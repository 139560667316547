
import { defineComponent, ref } from 'vue';
import { ElInput } from 'element-plus';
import { useRoute } from 'vue-router';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { getErrorMsg, instanceOfIVatTuWithSoLuongDaXuat, swalErrNotification, swalNotification } from '@/core/helpers/utils';
import { ICapSuaChuaResponseBase, IKeHoachSuaChuaResponseBase, IVatTuResponseBase, IVatTuWithSoLuongDaXuat } from '@/core/interfaces/ApiResponses';
import { KeHoachSuaChuaService } from '@/core/services/KeHoachSuaChua.service';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useCreatingForm } from '@/core/hooks/use-creating-form';
import { AddVatTuItemsFor } from '@/core/enums/addVatTuItemsFor.enum';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { useLoading } from '@/core/hooks/use-loading';
import { BienBanGiaiTheService } from '@/core/services/BienBanGiaiThe.service';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { CapSuaChuaService } from '@/core/services/CapSuaChuaService.service';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import LabelCustom from '../../../../components/shared/label-custom/LabelCustom.vue';
import AddVatTuItemV2 from '../../../../components/add-vat-tu-items-v2/AddVatTuItemV2.vue';
import AddVatTuTableItems from '../../../../components/add-vat-tu-items-v2/add-vat-tu-table-items/AddVatTuTableItems.vue';
import ToaXeService from '../../../../core/services/ToaXe.service';
import DanhMucToaXeService from '../../../../core/services/DanhMucToaXe.service';
import ResponsiveLayout from '@/layout/_shared/ResponsiveLayout.vue';

export default defineComponent({
  name: 'bien-ban-giai-the-form',

  components: {
    AddVatTuItemV2,
    AddVatTuTableItems,
    ButtonCustom,
    LabelCustom,
    PageRowLayout,
    ResponsiveLayout,
  },

  setup() {
    const { formRef } = useCreatingForm();
    const { params: { idKeHoach, idBienBan }, name } = useRoute();
    const { goBack, push, replace, searchInUri } = useRouterCustom();
    const { isCreating } = useCreatingForm();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom();
    const { startLoading, endLoading } = useLoading();
    const { moment } = useDateTime();
    const toaXeSearchRef = ref<null | typeof ElInput>(null);
    const soBienBanInputhRef = ref<null | typeof ElInput>(null);

    return {
      idKeHoach: idKeHoach ? +idKeHoach : 0,
      idBienBan: idBienBan ? +idBienBan : 0,
      goBack, push, replace, searchInUri, routeName: name,
      formRef, toaXeSearchRef, soBienBanInputhRef,
      AddVatTuItemsFor,
      ButtonTypeColors, ButtonsType,
      startLoading, endLoading,
      moment,
      isCreating,
    }
  },

  data() {
    const capSuaChuaValidation = (_rule, value, fn) => {
      if (!value) {
        fn(new Error('Bắt buộc phải chọn cấp sửa chữa'))
      } else {
        fn();
      }
    }

    return {
      capSuaChuaItems: [] as ICapSuaChuaResponseBase[],
      keHoachDetail: null as IKeHoachSuaChuaResponseBase | null,
      form: {
        so_bien_ban: '',
        ngay_vao_xuong: new Date(),
        has_dinh_muc_dong: false,
        cap_sua_chua_id: 0,
      },
      danhMucToaXeId: 0,
      tenDanhMucToaXe: '',
      isDanhMucItemsAvailable: true,
      isBelongedToKeHoach: true,
      vatTuItems: [] as IVatTuWithSoLuongDaXuat[],
      isDinhMucDongModalOpen: false,
      tenToaXe: '',
      toaXeId: 0,
      isFoundToaXe: true,
      rules: {
        so_bien_ban: [
          {
            required: true,
            message: 'Số biên bản bắt buộc phải nhập',
            trigger: 'blur',
          },
        ],
        ngay_vao_xuong: [
          {
            required: true,
            message: 'Bạn phải nhập ngày vào xưởng',
            trigger: 'change',
          },
        ],
        cap_sua_chua_id: [
          {
            validator: capSuaChuaValidation,
            trigger: 'blur',
          },
        ]
      },
      soBienBanParent: '',
    }
  },

  computed: {
    formTitle() {
      return this.isCreating ? 'Tạo mới' : 'Cập nhật';
    },

    isCreatingBienBanGiaiTheBoSung() {
      return this.routeName === 'tao-bbgt-bo-sung';
    },

    isSearchForToaXeInputDisabled(): boolean {
      return !this.isBelongedToKeHoach && !this.danhMucToaXeId
    },
  },

  async mounted() {
    setCurrentPageTitle('Biên bản giải thể');

    this.isBelongedToKeHoach = this.searchInUri('ke-hoach-sua-chua');

    await this.startLoading();

    if (!this.isBelongedToKeHoach) {
      await this.fetchCapSuaChuaItems();
    }

    if (this.isCreatingBienBanGiaiTheBoSung) {
      await this.fetchBienBan();

      await this.fetchToaXe();
    }

    if (this.isCreating && this.isBelongedToKeHoach) {
      await this.fetchKeHoach();
    } else if (!this.isCreating) {
      await this.fetchBienBan();

      if (this.isBelongedToKeHoach) {
        await this.fetchKeHoach()
      }

      await this.fetchToaXe();
    }

    await this.endLoading();
  },

  methods: {
    onSelectDanhMucToaXe({ id }) {
			this.danhMucToaXeId = id;
		},

    async fetchCapSuaChuaItems() {
      try {
        this.startLoading();

        const { data: { data: { data } } } = await CapSuaChuaService.list({});

        this.capSuaChuaItems = data;

        this.endLoading();
      } catch(e) {
        this.endLoading();
        swalNotification(
          getErrorMsg(e, 'Có lỗi khi lấy thông tin cấp sửa chữa'),
          'error',
        );
      }
    },

    async queryDanhMucToaXe(queryString: string, fn) {
			const { data: { data: { data } } } = await DanhMucToaXeService.list(
        1,
        1000,
        queryString,
      );

			if (data.length === 0 && queryString) {
				this.isDanhMucItemsAvailable = false;
			} else if (data.length >= 0) {
				this.isDanhMucItemsAvailable = true;
			}

			fn(data);
		},

    async queryToaXe(term: string, callback): Promise<void> {
      const { data: { data: { data } } } = await ToaXeService.list(
        1,
        200,
        this.keHoachDetail?.id_danh_muc_toa_xe || this.danhMucToaXeId,
        term,
      );

      if (!data.length) {
        this.isFoundToaXe = false;
      } else {
        this.isFoundToaXe = true;
      }

      callback(data);
    },

    onSelectToaXe({ id, ten_toa_xe, ma_toa_xe }) {
      this.toaXeId = id;
      this.tenToaXe = `${ma_toa_xe} - ${ten_toa_xe}`

      if (this.soBienBanInputhRef) {
        this.soBienBanInputhRef.focus();
      }
    },

    async fetchKeHoach() {
      try {
        const { data: { data } } = await KeHoachSuaChuaService.get(this.idKeHoach);
        this.keHoachDetail = data;
      } catch (e) {
        await this.endLoading();

        await swalNotification(
          getErrorMsg(e, 'Có lỗi khi lấy thông tin kế hoạch'),
          'error',
        );
      }
    },

    async fetchBienBan() {
      try {
        const { data: { data: {
          has_dinh_muc_dong,
          so_bien_ban,
          id_ke_hoach_sua_chua,
          id_toa_xe,
          ngay_vao_xuong,
          ngoai_dinh_muc: vatTuNgoaiDinhMucItems,
          id_cap_sc,
          ke_hoach_sua_chua,
          // phieu_xuat_kho,
        } } } = await BienBanGiaiTheService.get(this.idBienBan);

        this.form.has_dinh_muc_dong = has_dinh_muc_dong;
        this.form.so_bien_ban = so_bien_ban;
        this.idKeHoach = id_ke_hoach_sua_chua;
        this.toaXeId = id_toa_xe;
        this.form.ngay_vao_xuong = ngay_vao_xuong;
        this.form.cap_sua_chua_id = id_cap_sc || ke_hoach_sua_chua?.id_cap_sc || 0;

        if (id_ke_hoach_sua_chua) this.isBelongedToKeHoach = true;

        if (this.isCreatingBienBanGiaiTheBoSung) {
          this.form.has_dinh_muc_dong = false;
          this.form.so_bien_ban = '';
          this.vatTuItems = [];
          this.form.ngay_vao_xuong = new Date();
          this.soBienBanParent = so_bien_ban;

          return;
        }

        this.vatTuItems = vatTuNgoaiDinhMucItems.map(item => {
          const so_luong_ban_dau = item.pivot.so_luong;

          let soLuongDaXuat = item.so_luong_da_xuat_kho;

          return {
            ...item,
            ...(item.pivot.ghi_chu && {
              ghi_chu: item.pivot.ghi_chu,
            }),
            so_luong: so_luong_ban_dau - soLuongDaXuat,
            so_luong_da_xuat_kho: soLuongDaXuat,
          };
        });
      } catch (e) {
        this.endLoading();
        swalNotification(
          getErrorMsg(e, 'Có lỗi khi lấy thông tin biên bản'),
          'error',
        );
      }
    },

    async fetchToaXe() {
      try {
        const { data: { data: { ten_toa_xe, ma_toa_xe } } } = await ToaXeService.get(this.toaXeId);
        this.tenToaXe = `${ma_toa_xe} - ${ten_toa_xe}`;
      } catch (e) {
        this.endLoading();
        await swalErrNotification(e, 'Có lỗi xảy ra, không thể lấy thông tin toa xe này');
      }
    },

    onAddedVatTu({ selectingVatTuItems }: { selectingVatTuItems: IVatTuResponseBase[] }) {
      const newVatTuItems: IVatTuWithSoLuongDaXuat[] = selectingVatTuItems.map(item => ({
        ...item,
        so_luong_da_xuat_kho: 0,
        ghi_chu: '',
      }));

      this.vatTuItems.push(...newVatTuItems);

      this.isDinhMucDongModalOpen = false;
    },

    onRemovingVatTu(id: number) {
      this.vatTuItems = this.vatTuItems.filter(({ id: idVatTu }) => idVatTu !== id)
    },

    ghiChuUpdateHandler({
      ghi_chu,
      id,
    }: {
      ghi_chu: string;
      id: number;
    }) {
      const vatTuItem = this.vatTuItems.find(({ id: idVatTu }) => idVatTu === id);

      if (vatTuItem && instanceOfIVatTuWithSoLuongDaXuat(vatTuItem)) {
        vatTuItem.ghi_chu = ghi_chu;
      }
    },

    soLuongChangedHandler({
      selectingVatTu,
      so_luong_moi,
    }: {
      selectingVatTu: IVatTuWithSoLuongDaXuat,
      so_luong_moi: number,
    }) {
      const vatTuItem = this.vatTuItems.find(item => item.id === selectingVatTu.id);

      if (vatTuItem && instanceOfIVatTuWithSoLuongDaXuat(vatTuItem)) {
        vatTuItem.so_luong = so_luong_moi;
      }
    },

    async submit() {
      if (this.form.has_dinh_muc_dong && !this.vatTuItems.length) {
        swalNotification(
          'Phải nhập vật tư trong định mức động',
          'error',
        );

        return;
      }

      if (!this.formRef) {
        return;
      }

      if (!this.toaXeId) {
        await swalNotification(
          'Phải chọn toa xe',
          'error',
        );

        if (this.toaXeSearchRef) {
          this.toaXeSearchRef.focus()
        }

        return;
      }

      this.formRef.validate(async (valid) => {
        if (valid) {
          await this.startLoading();

          const buildPayload = () => ({
            so_bien_ban: this.form.so_bien_ban,
            id_ke_hoach_sua_chua: this.isBelongedToKeHoach && this.keHoachDetail
              ? this.keHoachDetail.id
              : 0,
            id_cap_sc: this.isBelongedToKeHoach && this.keHoachDetail
              ? 0
              : this.form.cap_sua_chua_id,
            ...(this.isCreatingBienBanGiaiTheBoSung && {
              id_cha: this.idBienBan,
            }),
            id_toa_xe: this.toaXeId,
            has_dinh_muc_dong: this.form.has_dinh_muc_dong,
            ngay_vao_xuong: this.moment(this.form.ngay_vao_xuong.toString()).format('YYYY-MM-DD'),
            vat_tu_items: this.vatTuItems.map(item => ({
              ...item,
              so_luong: +item.so_luong + +item.so_luong_da_xuat_kho,
              ...(item.ghi_chu && {
                ghi_chu: item.ghi_chu,
              }),
            })),
            ...(!this.isCreating && !this.isCreatingBienBanGiaiTheBoSung && {
              id_bien_ban: this.idBienBan,
            }),
          })

          try {
            if (
              (
                this.keHoachDetail &&
                this.isBelongedToKeHoach
              ) || !this.isBelongedToKeHoach
            ) {
              const { data: { data: { id } } } = await BienBanGiaiTheService.createOrUpdate(buildPayload());

              await this.endLoading();

              await swalNotification(
                `${this.isCreating ? 'Tạo mới' : 'Cập nhật'} biên bản thành công`,
                'success',
              );

              await this.replace(`/quan-ly/bien-ban-giai-the/chi-tiet/${id}`);
            }
          } catch (e) {
            this.endLoading();

            await swalNotification(
              getErrorMsg(e),
              'error',
            );
          }
        } else return false;
      });
    }
  }
})
