import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock, renderList as _renderList, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-danger" }
const _hoisted_2 = { class: "text-danger" }
const _hoisted_3 = { class: "w-100 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddVatTuItemV2 = _resolveComponent("AddVatTuItemV2")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_LabelCustom = _resolveComponent("LabelCustom")!
  const _component_ResponsiveLayout = _resolveComponent("ResponsiveLayout")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_AddVatTuTableItems = _resolveComponent("AddVatTuTableItems")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AddVatTuItemV2, {
      "is-modal-open": _ctx.isDinhMucDongModalOpen,
      "use-for": _ctx.AddVatTuItemsFor.DINH_MUC,
      "vat-tu-selected": _ctx.vatTuItems,
      onAdded: _ctx.onAddedVatTu,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDinhMucDongModalOpen = false))
    }, null, 8, ["is-modal-open", "use-for", "vat-tu-selected", "onAdded"]),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        (_ctx.isCreatingBienBanGiaiTheBoSung)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" Tạo mới BBGT bổ sung cho biên bản số "),
              _createVNode(_component_RouterLink, {
                to: `/quan-ly/bien-ban-giai-the/chi-tiet/${_ctx.idBienBan}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.soBienBanParent), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.formTitle), 1)
            ], 64))
      ]),
      "page-row-action-button": _withCtx(() => [
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.BACK,
          onClick: _ctx.goBack
        }, null, 8, ["button-type", "onClick"])
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.rules,
          onSubmit: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            (_ctx.isBelongedToKeHoach)
              ? (_openBlock(), _createBlock(_component_ResponsiveLayout, { key: 0 }, _createSlots({
                  "col-1": _withCtx(() => [
                    _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                      default: _withCtx(() => [
                        _createTextVNode("Kế hoạch:")
                      ]),
                      _: 1
                    })
                  ]),
                  "col-3": _withCtx(() => [
                    _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                      default: _withCtx(() => [
                        _createTextVNode("DM toa xe:")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, [
                  (_ctx.keHoachDetail)
                    ? {
                        name: "col-2",
                        fn: _withCtx(() => [
                          _createVNode(_component_RouterLink, {
                            target: "_blank",
                            to: {
                  name: 'chi-tiet-ke-hoach-sua-chua',
                  params: {
                    id: _ctx.keHoachDetail.id,
                  },
                }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.keHoachDetail.id) + " - " + _toDisplayString(_ctx.keHoachDetail.ten_ke_hoach), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (_ctx.keHoachDetail?.danh_muc_toa_xe.id)
                    ? {
                        name: "col-4",
                        fn: _withCtx(() => [
                          _createVNode(_component_RouterLink, {
                            target: "_blank",
                            to: {
                  name: 'chi-tiet-danh-muc-toa-xe',
                  params: {
                    id: _ctx.keHoachDetail.danh_muc_toa_xe.id
                  }
                }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.keHoachDetail?.danh_muc_toa_xe.id) + " - " + _toDisplayString(_ctx.keHoachDetail?.danh_muc_toa_xe.ten_danh_muc), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1024))
              : _createCommentVNode("", true),
            (!_ctx.isBelongedToKeHoach)
              ? (_openBlock(), _createBlock(_component_ResponsiveLayout, { key: 1 }, {
                  "col-1": _withCtx(() => [
                    _createVNode(_component_LabelCustom, {
                      "is-required": true,
                      "is-text-muted": true
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Cấp sửa chữa:")
                      ]),
                      _: 1
                    })
                  ]),
                  "col-2": _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      prop: "cap_sua_chua_id",
                      class: "mb-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.form.cap_sua_chua_id,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.cap_sua_chua_id) = $event)),
                          placeholder: "Chọn danh mục"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              label: "Chưa chọn",
                              value: 0
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.capSuaChuaItems, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.id,
                                value: item.id,
                                label: item.ten_cap_sc
                              }, null, 8, ["value", "label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.isBelongedToKeHoach)
              ? (_openBlock(), _createBlock(_component_ResponsiveLayout, { key: 2 }, {
                  "col-1": _withCtx(() => [
                    _createVNode(_component_LabelCustom, {
                      "is-text-muted": true,
                      "is-required": true
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" DM Toa Xe: ")
                      ]),
                      _: 1
                    })
                  ]),
                  "col-2": _withCtx(() => [
                    _createVNode(_component_el_autocomplete, {
                      modelValue: _ctx.tenDanhMucToaXe,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tenDanhMucToaXe) = $event)),
                      ref: "danhMucToaXeInput",
                      class: "w-100 pt-2",
                      clearable: "",
                      placeholder: "Tìm kiếm DMTX",
                      "value-key": "ten_danh_muc",
                      disabled: _ctx.isCreatingBienBanGiaiTheBoSung || !_ctx.isCreating,
                      "fetch-suggestions": _ctx.queryDanhMucToaXe,
                      "highlight-first-item": true,
                      "trigger-on-focus": false,
                      onSelect: _ctx.onSelectDanhMucToaXe,
                      onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select()))
                    }, {
                      default: _withCtx(({ item }) => [
                        _createElementVNode("span", null, [
                          _createElementVNode("strong", null, _toDisplayString(item.ma_danh_muc) + " - ", 1)
                        ]),
                        _createElementVNode("span", null, _toDisplayString(item.ten_danh_muc), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "disabled", "fetch-suggestions", "onSelect"]),
                    _withDirectives(_createElementVNode("span", _hoisted_1, " Không có dữ liệu ", 512), [
                      [_vShow, !_ctx.isDanhMucItemsAvailable]
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ResponsiveLayout, null, {
              "col-1": _withCtx(() => [
                _createVNode(_component_LabelCustom, {
                  "is-text-muted": true,
                  "is-required": ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Toa xe:")
                  ]),
                  _: 1
                })
              ]),
              "col-2": _withCtx(() => [
                _createVNode(_component_el_autocomplete, {
                  modelValue: _ctx.tenToaXe,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tenToaXe) = $event)),
                  ref: "toaXeSearchRef",
                  class: "w-100",
                  clearable: "",
                  placeholder: "Tìm kiếm toa xe",
                  disabled: _ctx.isSearchForToaXeInputDisabled || !_ctx.isCreating || _ctx.isCreatingBienBanGiaiTheBoSung,
                  "trigger-on-focus": false,
                  "fetch-suggestions": _ctx.queryToaXe,
                  "highlight-first-item": true,
                  onFocus: _cache[5] || (_cache[5] = ($event: any) => ($event.target.select())),
                  onSelect: _ctx.onSelectToaXe
                }, {
                  default: _withCtx(({ item }) => [
                    _createElementVNode("span", null, [
                      _createElementVNode("strong", null, _toDisplayString(item.ma_toa_xe) + " - ", 1)
                    ]),
                    _createElementVNode("span", null, _toDisplayString(item.ten_toa_xe), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "disabled", "fetch-suggestions", "onSelect"]),
                _withDirectives(_createElementVNode("span", _hoisted_2, "Không có dữ liệu", 512), [
                  [_vShow, !_ctx.isFoundToaXe]
                ])
              ]),
              "col-3": _withCtx(() => [
                _createVNode(_component_LabelCustom, {
                  "is-text-muted": true,
                  "is-required": true
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Số biên bản:")
                  ]),
                  _: 1
                })
              ]),
              "col-4": _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "so_bien_ban",
                  class: "mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.form.so_bien_ban,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.so_bien_ban) = $event)),
                      ref: "soBienBanInputhRef",
                      name: "so_bien_ban",
                      disabled: !_ctx.isCreating,
                      onFocus: _cache[7] || (_cache[7] = ($event: any) => ($event.target.select()))
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ResponsiveLayout, null, {
              "col-1": _withCtx(() => [
                _createVNode(_component_LabelCustom, {
                  "is-text-muted": true,
                  "is-required": true
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ngày vào xưởng:")
                  ]),
                  _: 1
                })
              ]),
              "col-2": _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "ngay_vao_xuong",
                  class: "mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.form.ngay_vao_xuong,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.ngay_vao_xuong) = $event)),
                      disabled: !_ctx.isCreating,
                      class: "w-100",
                      placeholder: "Chọn ngày",
                      name: "signDate",
                      format: "DD/MM/YYYY"
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ResponsiveLayout, null, {
              "col-1": _withCtx(() => [
                _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Định mức động:")
                  ]),
                  _: 1
                })
              ]),
              "col-2": _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  prop: "has_dinh_muc_dong",
                  class: "mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.form.has_dinh_muc_dong,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.has_dinh_muc_dong) = $event)),
                      size: "large",
                      disabled: !_ctx.isCreating
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ResponsiveLayout, null, {
              "col-4": _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.form.has_dinh_muc_dong)
                    ? (_openBlock(), _createBlock(_component_ButtonCustom, {
                        key: 0,
                        "button-type": _ctx.ButtonsType.ADD,
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isDinhMucDongModalOpen = true))
                      }, {
                        "button-label": _withCtx(() => [
                          _createTextVNode(" Thêm vật tư ")
                        ]),
                        _: 1
                      }, 8, ["button-type"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ButtonCustom, {
                    "button-type": _ctx.ButtonsType.SAVE,
                    "color-type": _ctx.ButtonTypeColors.SUCCESS,
                    onClick: _ctx.submit
                  }, {
                    "button-label": _withCtx(() => [
                      _createTextVNode("Lưu biên bản")
                    ]),
                    _: 1
                  }, 8, ["button-type", "color-type", "onClick"])
                ])
              ]),
              _: 1
            }),
            (_ctx.form.has_dinh_muc_dong)
              ? (_openBlock(), _createBlock(_component_AddVatTuTableItems, {
                  key: 3,
                  "disable-ghi-chu-input": false,
                  items: _ctx.vatTuItems,
                  "is-loading": false,
                  "is-so-luong-editable": true,
                  "show-ghi-chu-input": true,
                  onOnDelete: _ctx.onRemovingVatTu,
                  onSoLuongChange: _ctx.soLuongChangedHandler,
                  onUpdateGhiChu: _ctx.ghiChuUpdateHandler
                }, null, 8, ["items", "onOnDelete", "onSoLuongChange", "onUpdateGhiChu"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    })
  ], 64))
}